import React from "react"
import Layout from "../components/layout"
import Link from 'gatsby-link'
import Helmet from 'react-helmet'

class IndexNotFound extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Helmet
          title="Page non trouvée"
        />
        <div className="container" style={{minHeight:'600px'}}>
          <div className="d-flex justify-content-center align-items-center">
            <div class="text-center">
              <h1>Page non trouvée..</h1>
              <Link className="btn btn-primary btn-block" to="/">Retour à la page d'accueil</Link>
              <br />
              <img src="img/404.jpg" alt="Chaton 404" />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexNotFound